



document.querySelectorAll('.teams__item').forEach(item => {
  item.addEventListener('focus', () => {
      item.classList.add('teams__item--active');
  });

  item.addEventListener('blur', () => {
      item.classList.remove('teams__item--active');
  });
});